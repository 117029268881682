import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | sobre nosotros"
        description="Nos gusta pensar en nosotros mismos como un negocio pequeño, preciso y cuidadoso."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>Sobre nosotros</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                Nos gusta pensar en nosotros mismos como un negocio pequeño,
                preciso y cuidadoso. Igual un bistró, siempre buscamos mejorar
                nuestra eficiencia.
                <br />
                <br />
                Llevamos a cabo nuestro negocio de manera ética y nos
                preocupamos por nuestra privacidad, la privacidad de nuestros
                clientes y los usuarios de nuestros productos.
                <br />
                <br />
                Ponemos el máximo esfuerzo y cuidado en cada detalle de cada
                proyecto en el que trabajamos, y brindamos a nuestros clientes
                el mismo trato que nos gustaría recibir.
                <br />
                <br />
                Estamos constantemente ampliando nuestras habilidades y buscando
                nuevas herramientas para dar la mejor respuesta a cada
                necesidad.
                <br />
                <br />Y por supuesto lo disfrutamos.
                <br />
                <br />
                Somos un Bistrot.
                <br />
                <br />
                &nbsp;
                <br />
                <br />
                Bienvenido!
              </span>
              <div className="h-16" />
              <div className="text-center">
                <Link
                  className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                  to="/es/lets-work-together/"
                >
                  Vamos a trabajar juntos?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="es" />
    </>
  )
}

export default IndexEN
